import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Студія Blanfer: Захоплення позачасових моментів
			</title>
			<meta name={"description"} content={"Перетворення сьогоднішніх моментів на завтрашні спогади\n"} />
			<meta property={"og:title"} content={"Студія Blanfer: Захоплення позачасових моментів"} />
			<meta property={"og:description"} content={"Перетворення сьогоднішніх моментів на завтрашні спогади\n"} />
			<meta property={"og:image"} content={"https://blanfer-ua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blanfer-ua.com/img/1839979.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blanfer-ua.com/img/1839979.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://blanfer-ua.com/img/1.jpg) 50% 15%/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-red"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text
							as="h4"
							margin="0"
							font="--base"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Захоплення позачасових моментів
						</Text>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							Фотостудія Blanfer
						</Text>
						<Text as="p" margin="16px 0" font="--base" max-width="400px">
							У Blanfer Studio ми віримо у здатність фотографії розповідати історії, які неможливо передати словами. Наші професійні послуги з фотозйомки покликані відобразити сутність кожної людини, сім'ї та події з елегантністю та артистизмом.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
							Що ми пропонуємо
						</Text>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
						>
							- Створено з турботою: Наші фотосесії - це більше, ніж просто клацання затвора - це ретельно продумані події, пристосовані до вашого бачення та стилю.
							<br />
							<br />
							- Різноманітне портфоліо: Від щирого сміху сімейних зустрічей до врівноваженої елегантності професійних фотографій, наше портфоліо демонструє нашу багатогранність і відданість якості.
							<br />
							<br />
							- Студія за останнім словом техніки: Оснащена найсучаснішими фототехнологіями, наша студія забезпечує ідеальні умови для будь-якого типу зйомок, гарантуючи продукт найвищого ґатунку.
							<br />
							<br />
							- Команда експертів: Наша команда професійних фотографів та стилістів прагне створити комфортну атмосферу співпраці, яка розкриє ваші найкращі якості.
							<br />
							<br />
							- Спогади, що тривають: ми надаємо зображення з високою роздільною здатністю, які витримують випробування часом, забезпечуючи вас спогадами, які ви можете плекати вічно.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://blanfer-ua.com/img/2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://blanfer-ua.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://blanfer-ua.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Чому варто обрати Blanfer Studio?
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					- Персоналізована увага: Ми приділяємо час, щоб познайомитися з вами, щоб кожна фотографія відображала вашу унікальну індивідуальність.
					<br />
					- Якісна майстерність: Завдяки уважному ставленню до деталей, ми гарантуємо, що кожне зображення є витвором мистецтва.
					<br />
					- Розслаблена атмосфера: Наша студія створена для того, щоб ви відчували себе невимушено, дозволяючи вашому справжньому "я" сяяти крізь кожну фотографію.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});